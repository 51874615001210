.container {
    display: flex;
    justify-content: space-between;
    padding: 0px;
    margin: 0px;
    color: #0c2140;
    padding: 1vw;
    border-top: 1px solid #a6adb8;
    margin-top: 100px
}

.container-span {
    width : 20vw;
    text-align: left;
}

#logo {
    height: 11vh;
}

#vm-ing {
    color: #0c2140
}

.imagenes a {
    color: grey;
    text-decoration: none;
}

.imagenes a :hover {
    cursor: default
}


@media (max-width: 850px){

    .container {
        flex-direction: column;
        margin:auto
    }

    .container-span {
        width : 100%;
    }
}