@font-face{
    font-family: RobotoH1;
    src: url(../src/fuentes/Roboto-Bold.ttf)
}

@font-face{
    font-family: RobotoH2;
    src: url(../src/fuentes/Roboto-Regular.ttf)
}

@font-face{
    font-family: RobotoP;
    src: url(../src/fuentes/Roboto-Light.ttf)
}

* {
    margin: 0;
    padding: 0;
    font-family: RobotoP;
}

h1 {
    font-family: RobotoH1;
}

h2 {
    font-family: RobotoH2;
}

.nav {
    background-color: #f8f8f8;
    display: block;
}

.clearfix {
    float: none;
    clear: both
}

.body {
    padding-top: 9vh
}