#navBar {
    height: 8vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: larger;
    padding: 2vh auto;
    position: fixed;
    z-index: 20;
    background-color: #fff;
}

#logo {
    height: 6vh;
    position: absolute;
    top: 1vh;
    left: 2px
}

.botonera {
    width: 35vw;
    display: flex;
    justify-content: flex-end;
    padding-right: 15px;
    z-index: 10S
}

.botonera a {
    color: #0c2140;
    text-decoration: none;
    margin: auto 0px;
    border-left: 1px solid #0c2140;
    padding: 0px 20px;
    text-align: center;
}

.primero {
    color: #0c2140;
    text-decoration: none;
    border-left: 0px;
    margin: auto 0px;
    padding: 0px 20px;
    text-align: center;
}

.botonera .active {
    text-decoration: underline;
}
    
.botonera :hover {
    text-decoration: underline
}

.hamburguesa {
    display: none;
    height: 30px;
    width: 30px;
    padding: auto;
    position: absolute;
    right: 5%;
    cursor: pointer;
    z-index: 15;
}

.hamburguesa span {
    display: block;
    width: 100%;
    height: 4px;
    background-color: rgb(12,33,64);
    border-radius: 3px;
    transform-origin: 0px 100%;
    transition: all 250ms
}

.line2, .line3, .line1 {
    margin-top: 6px;
    display: block
}

.hamburguesa .activeLine1 {
    transform:
        rotate(45deg)
        translate(-1px, 0px);
    background-color: #ffffffae;
}

.hamburguesa .activeLine3 {
    transform:
        rotate(-45deg)
        translate(-7px, 10px);
    background-color: #ffffffae;
}

.hamburguesa .activeLine2 {
    opacity: 0;
    margin-left: -30px;
}


@media (max-width: 850px){
    .botonera, .activeBotonera {
        height: 100vh;
        background-color: #00000091;
        position: relative;
        top: 45vh;
        display: flex;
        align-content: flex-end;
        transition: all 600ms;
        z-index: 1;
        width: 65vw;
        justify-content: end;
        flex-wrap: wrap;
        z-index: 10
    }

    .botonera {
        top: -55vh;
    }

    .activeBotonera div, .botonera div {
        height:24vh;
        width: 65vw;
        padding: 20px 30px;
    }

    .activeBotonera div a, .botonera div a {
        color: #ffffffda;
        text-decoration: none;
        text-align: left;
        font-weight: 900
    }
    
    .hamburguesa {
        display: block
    }
}