.container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 70vw;
    margin: auto
}

.error {
    border: 1px solid red
}

.errorText {
    color: red
}

form {
    width: 40vw;
    display: inline-block;
    margin-left: 10px
}

form textarea {
    width: 100%;
    min-width: 230px
}

input {
    font-size: medium;
    background-color: rgb(247, 247, 247)
}

textarea {
    font-size: medium;
    background-color: rgb(247, 247, 247)
}

.container {
    display: flex;
    justify-content: space-around;
    padding: 50px
}