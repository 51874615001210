#fotito {
    width: 100%
}

.body {
    width: 100%;
    display: flex;
    justify-items: center;
    flex-direction: column
}

.h1 {
    margin: 13vh auto 9vh auto;
    padding: auto;
    height: 6vh
}

.parrafo {
    font-size: x-large;
    width: 65vw;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    justify-items: justify;
}

.misionVision {
    display:flex;
    justify-content: space-between
}

.span {
    width:49%;
    margin: 0 25px
}

.p {
    text-align: justify
}

.descripcion {
    text-align: justify
}

.division {
    width: 1px;
    height: 80%;
    background-color: grey;
    margin: 0
}

.mision {
    margin: 0 50px 0 0
}

.vision {
    margin: 0 0 50px 0
}

.span h3 {
    text-align: center;
    margin-bottom: 10px;
}


@media (max-width: 850px) {

    .parrafo {
        font-size: large;
        width: 80vw;
    }

    .misionVision {
        flex-direction: column;
        width: 100%;
    }

    .mision, .vision {
        width: 80vw
    }

    .h1 {
        margin: 13vh auto 9vh auto;
        padding: auto;
        height: 6vh;
        font-size: x-large;
    }
}