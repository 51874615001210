.container {
    padding: 0px;
    margin: 0px
}

video{
    object-fit: cover;
    z-index: 1
}

.body {
    display: flex;
    justify-items: center;
    flex-direction: column;
}

.btn {
    color: #0c2140;
    font-weight: bolder;
    text-align: center;
    padding: 50px;
    border: 3px solid #0c2140;
    border-radius: .4em;
    font-size: xx-large;
}

.btn:hover {
    box-shadow: 2px 2px black;
    cursor: pointer;
}

.h1 {
    margin: 7vh auto;
    padding: auto;
    height: 6vh;
    margin-bottom: 0;
}

.parrafo span {
    font-size: x-large;
    width: 65vw;
    margin: 2em auto;
    display: flex;
    justify-content: space-around;
    justify-items: center;
    text-align: justify;
}


@media (max-width: 850px) {
    
    .parrafo span {
        font-size: large;
        width: 80vw
    }
    
    .h1 {
        font-size: x-large;
    }
}