.container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 70vw;
    margin: auto;
}

.servicios {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 70vw;
    margin: auto;
}

.servicio {
    position: relative;
    width: 280px;
    height: 350px;
    margin: 40px 15px
}

.servicio .face {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 10px;
    overflow: hidden;
    transition: .4s
}

.front {
    transform: perspective(600px) rotateY(0deg);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: space-around;
    border: 1px solid #0c2140;
    border-radius: 10px
}

.back {
    transform: perspective(600px) rotateY(180deg);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: space-between;
    align-items: center;
    border: 1px solid #0c2140;
    border-radius: 10px;
    font: justify
}

.servicio .back p {
    display: block;
    padding: 0px 40px
}

.servicio .front img{
    width: 70%;
    margin-bottom: 35px;
}

.servicio .back:hover {
    cursor: pointer
}

.servicio:hover .front {
    transform: perspective(600px) rotateY(180deg);
    cursor: pointer
}

.servicio:hover .back {
    transform: perspective(600px) rotateY(360deg);
}

.listElement {
    text-align: left;
    font-size: x-large ;
}

.servicioTitulo {
    text-decoration: underline;
}

/* .propContainer {
    transform: perspective(600px) rotateY(720deg);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 99%;
    margin: auto;
    position: relative;
    border: 1px solid #0c2140;
    border-radius: 10px;
    transition: .4s
} */